<template>
    <Layout class="container" :panels="panels" style="background-color: #01132C;" :padding="20" :gutter="20" :loading="loading">
      
    </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import moment from 'moment'
import UTIL from '@/utils'
moment.locale('zh-cn')
export default {
  name: "test1",
  components:{Layout},
  data(){
    return {
      loading:true,
      config:{}
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    getData(){
      const types = ['全过程咨询','项目管理','造价咨询','市政监理','房建监理','BIM咨询','其他']
      this.$api.get("public/data/cm?key=nbgz").then(res=>{
        let config = res.data.data
        let cca = UTIL.formatSalaryObject(config.current_contract_amount)
        config.current_contract_amount = parseFloat(cca.value)
        config.current_contract_amount_unit = cca.unit
        let ua = UTIL.formatSalaryObject(config.untransferred_amount)
        config.untransferred_amount = parseFloat(ua.value)
        config.untransferred_amount_unit = ua.unit
        
        if(config.typed_projects){
          config.typed_projects.sort((a,b)=>{
            return types.findIndex(v=>v==a.type) > types.findIndex(v=>v==b.type)?1:-1
          })
          let total = config.typed_projects.reduce((acc,item)=>{
            return acc + item.amount
          },0)
          
          config.typed_projects.forEach(v=>{
            v.percent = parseInt(v.amount * 1000 / total) / 10
          })
        }

        if(config.recent_bills){
          config.recent_bills.forEach(v=>{
            v.amount = UTIL.formatSalary(v.amount)

            v.billed_at = moment(v.billed_at).fromNow()
          })
        }

        if(config.recent_transfers){
          config.recent_transfers.forEach(v=>{
            v.amount = UTIL.formatSalary(v.amount)

            v.billed_at = moment(v.billed_at).fromNow()
          })
        }

        let bp = UTIL.formatSalaryObject(config.bill_plan_amount)
        config.bill_plan_amount = parseFloat(bp.value)
        config.bill_plan_amount_unit = bp.unit
        bp = UTIL.formatSalaryObject(config.bill_amount)
         config.bill_amount = parseFloat(bp.value)
         config.bill_amount_unit = bp.unit+'元'

        let tp = UTIL.formatSalaryObject(config.total_pv)
        config.total_pv_amount = parseFloat(tp.value)
        config.total_pv_unit = tp.unit
        tp = UTIL.formatSalaryObject(config.actual_pv)
        config.pv_amount = parseFloat(tp.value)
        config.pv_unit = tp.unit+'元'

        tp = UTIL.formatSalaryObject(config.plan_hrvalue)
        config.plan_hrvalue = parseFloat(tp.value)
        config.plan_hrvalue_unit = tp.unit+'元'
        tp = UTIL.formatSalaryObject(config.hrbill)
        config.hrbill = parseFloat(tp.value)
        config.hrbill_unit = tp.unit+'元'
        tp = UTIL.formatSalaryObject(config.actual_hrvalue)
        config.actual_hrvalue = parseFloat(tp.value)
        config.actual_hrvalue_unit = tp.unit+'元'

        config.hr_projects.forEach(v=>{
          v.amount = UTIL.formatSalary(v.amount)
           v.hrvalue = UTIL.formatSalary(v.hrvalue)
        })

        this.config = config
        this.$forceUpdate()

        setTimeout(()=>{
          this.loading = false
        },1000)
      })
    }
  },
  computed:{
    panels(){
      return [{
        x:0.6,y:0.3,w:8,h:1,
        control:"PanelCaption",
        data:{
          name:"各事业部经营情况",
          sub:"宁波高专成本合约系统数字化平台 v2.0.0"
        }
      },]
    }
  },
}
</script>